import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailIzyfitPage = (props) => {
  return (
    <div>
      <Seo /> 
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/izyfit-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            UX/UI Design - Wellness App
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">This was my first individual project that was part of the UX/UI Design bootcamp at Ironhack. It was spread onto 3 days. The scope of this project consisted in proposing a new mobile app, designed to support and improve one’s wellness: I chose physical wellness. </p>
            <p className="mb-4">From there on, I used the Design Thinking Framework throughout the whole design process in order to uncover major pain points related to that topic, as well as needs. This allowed me to focus and develop features around them. I have created an app for people willing to be more active physically, but don’t have the time nor the motivation to exercise: “IZIFIT”. </p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Ironhack</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">UX/UI Design</p>
            <h2 className = { subtitle }> Documentation </h2>
            <a className="block mb-2 underline" href="https://medium.com/@charlottebotermans/wellness-app-user-based-research-approach-to-propose-and-develop-a-mobile-app-prototype-68507f20c5c3" target="_blank" rel="noreferrer"> Medium report </a>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/izyfit-01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3 ">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/izyfit-02.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailIzyfitPage